const GenericLetters = {
    "1":{
        "type":"letter",
        "content":"1"
    },
    "2":{
        "type":"letter",
        "content":"2"
    },
    "3":{
        "type":"letter",
        "content":"3"
    },
    "4":{
        "type":"letter",
        "content":"4"
    },
    "5":{
        "type":"letter",
        "content":"5"
    },
    "6":{
        "type":"letter",
        "content":"6"
    },
    "7":{
        "type":"letter",
        "content":"7"
    },
    "8":{
        "type":"letter",
        "content":"8"
    },
    "9":{
        "type":"letter",
        "content":"9"
    },
    "0":{
        "type":"letter",
        "content":"0"
    },
    "a":{
        "type":"letter",
        "content":"A"
    },
    "b":{
        "type":"letter",
        "content":"B"
    },
    "c":{
        "type":"letter",
        "content":"C"
    },
    "d":{
        "type":"letter",
        "content":"D"
    },
    "e":{
        "type":"letter",
        "content":"E"
    },
    "f":{
        "type":"letter",
        "content":"F"
    },
    "g":{
        "type":"letter",
        "content":"G"
    },
    "h":{
        "type":"letter",
        "content":"H"
    },
    "i":{
        "type":"letter",
        "content":"I"
    },
    "j":{
        "type":"letter",
        "content":"J"
    },
    "k":{
        "type":"letter",
        "content":"K"
    },
    "l":{
        "type":"letter",
        "content":"L"
    },
    "m":{
        "type":"letter",
        "content":"M"
    },
    "n":{
        "type":"letter",
        "content":"N"
    },
    "o":{
        "type":"letter",
        "content":"O"
    },
    "p":{
        "type":"letter",
        "content":"P"
    },
    "q":{
        "type":"letter",
        "content":"Q"
    },
    "r":{
        "type":"letter",
        "content":"R"
    },
    "s":{
        "type":"letter",
        "content":"S"
    },
    "t":{
        "type":"letter",
        "content":"T"
    },
    "u":{
        "type":"letter",
        "content":"U"
    },
    "v":{
        "type":"letter",
        "content":"V"
    },
    "w":{
        "type":"letter",
        "content":"W"
    },
    "x":{
        "type":"letter",
        "content":"X"
    },
    "y":{
        "type":"letter",
        "content":"Y"
    },
    "z":{
        "type":"letter",
        "content":"Z"
    },
};

export default GenericLetters;